import { useRoutes, RouteObject } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import CanvasRoutes from './CanvasRoutes';
import ChatbotRoutes from './ChatbotRoutes';

export default function ThemeRoutes() {
  return useRoutes(
    [
      MainRoutes,
      CanvasRoutes,
      ChatbotRoutes,
    ] as RouteObject[]
  );
}
