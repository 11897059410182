import axios from 'axios';
import { socket } from '@/utils/socket';
import { env } from '@/env';
const SERVER_URL = env.REACT_APP_SERVER_URL;

if(!SERVER_URL) {
  throw new Error('REACT_APP_SERVER_URL is not defined');
}

const apiClient = axios.create({
  withCredentials: true,
  baseURL: `${SERVER_URL}/api/v1`,
  headers: {
    'Content-type': 'application/json',
  },
});

apiClient.interceptors.request.use(async function (config) {
  try {
    const token = await Clerk.session.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    if (socket.connected && socket.id) {
      config.headers['Socket-Id'] = socket.id;
    }
  } catch (error) {
    console.log(`Error trying to get the token: ${error}`);
  }

  return config;
});

export default apiClient;
