import MinimalLayout from '@/layout/MinimalLayout';
import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
const ChatbotPage = lazy(() => import('@/views/chatbot/ChatbotPage'));

const ChatbotRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/chatbot/:id',
      element: <ChatbotPage />,
    },
  ] as RouteObject,
};

export default ChatbotRoutes;
